import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`I säsongsavslutningen går halva internet ner, Plasma poleras, Seb testar nya Firefox live och HaveIBeenPWNED öppnar källkod. Detta och som vanligt mycket mer! Glad sommar trevliga lyssnare!`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Valves "SteamPal": `}<a parentName="li" {...{
          "href": "https://arstechnica.com/gaming/2021/05/exclusive-valve-is-making-a-switch-like-portable-gaming-pc/"
        }}>{`https://arstechnica.com/gaming/2021/05/exclusive-valve-is-making-a-switch-like-portable-gaming-pc/`}</a></li>
    </ul>
    <h2>{`Kort och gott`}</h2>
    <ul>
      <li parentName="ul">{`Nya Firefox: `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/89.0/releasenotes/"
        }}>{`https://www.mozilla.org/en-US/firefox/89.0/releasenotes/`}</a></li>
      <li parentName="ul">{`Nya Chrome: `}<a parentName="li" {...{
          "href": "https://developer.chrome.com/blog/new-in-chrome-91/"
        }}>{`https://developer.chrome.com/blog/new-in-chrome-91/`}</a></li>
      <li parentName="ul">{`TikTok samlar data: `}<a parentName="li" {...{
          "href": "https://www.businessinsider.com/tiktok-privacy-policy-update-lets-it-collect-users-biometric-data-2021-6"
        }}>{`https://www.businessinsider.com/tiktok-privacy-policy-update-lets-it-collect-users-biometric-data-2021-6`}</a></li>
      <li parentName="ul">{`Dagen internet gick sönder (igen) (och sedan lagades på en timma)`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.svd.se/expert-skrammande-med-sa-stort-avbrott"
            }}>{`https://www.svd.se/expert-skrammande-med-sa-stort-avbrott`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.svtplay.se/video/31520082/nyheter-direkt/flera-stora-nyhetssajter-nere?id=jpAzQRx"
            }}>{`https://www.svtplay.se/video/31520082/nyheter-direkt/flera-stora-nyhetssajter-nere?id=jpAzQRx`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://techcrunch.com/2021/06/08/numerous-popular-websites-are-facing-an-outage/"
            }}>{`https://techcrunch.com/2021/06/08/numerous-popular-websites-are-facing-an-outage/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://status.fastly.com/"
            }}>{`https://status.fastly.com/`}</a></li>
        </ul></li>
      <li parentName="ul">{`KDE Plasma 5.22: `}<a parentName="li" {...{
          "href": "https://pointieststick.com/2021/06/04/this-week-in-kde-plasma-5-22-is-nigh/"
        }}>{`https://pointieststick.com/2021/06/04/this-week-in-kde-plasma-5-22-is-nigh/`}</a></li>
      <li parentName="ul">{`haveibeenpwned går (lite) opensource: `}<a parentName="li" {...{
          "href": "https://thenextweb.com/news/have-i-been-pwned-open-source-fbi"
        }}>{`https://thenextweb.com/news/have-i-been-pwned-open-source-fbi`}</a></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Livesessionen`}</li>
      <li parentName="ul">{`Alex plasma/tiling-kris`}<ul parentName="li">
          <li parentName="ul">{`Krohnkite kanske inte är så dumt`}</li>
          <li parentName="ul">{`Alex funderar på fedora plasma + krohnkite + snapper (btrfs) + wayland + pipewire`}</li>
        </ul></li>
      <li parentName="ul">{`Säsongsavslutning`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`YouTube: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCRVmpkj-XM6UhUCjGiL3hhQ"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://mastodon.linuxkompis.se/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Matrix: `}<a parentName="li" {...{
          "href": "https://matrix.to/#/%23TrevligMjukvara:matrix.org"
        }}>{`#TrevligMjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`Pixelland by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4222-pixelland"
                }}>{`https://filmmusic.io/song/4222-pixelland`}</a></li>
            </ul></li>
          <li parentName="ul">{`NewsSting by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4124-newssting"
                }}>{`https://filmmusic.io/song/4124-newssting`}</a></li>
            </ul></li>
          <li parentName="ul">{`Toccata and Fugue in D Minor by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4533-toccata-and-fugue-in-d-minor"
                }}>{`https://filmmusic.io/song/4533-toccata-and-fugue-in-d-minor`}</a></li>
            </ul></li>
          <li parentName="ul">{`Beautiful World by Rafael Krux`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/5616-beautiful-world-"
                }}>{`https://filmmusic.io/song/5616-beautiful-world-`}</a></li>
            </ul></li>
          <li parentName="ul">{`Dreamy Flashback by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/3679-dreamy-flashback"
                }}>{`https://filmmusic.io/song/3679-dreamy-flashback`}</a></li>
            </ul></li>
          <li parentName="ul">{`Black Knight by Rafael Krux`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/5617-black-knight-"
                }}>{`https://filmmusic.io/song/5617-black-knight-`}</a></li>
            </ul></li>
          <li parentName="ul">{`Pixel Peeker Polka - Faster by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4220-pixel-peeker-polka---faster"
                }}>{`https://filmmusic.io/song/4220-pixel-peeker-polka---faster`}</a></li>
            </ul></li>
          <li parentName="ul">{`Backbay Lounge by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/3408-backbay-lounge"
                }}>{`https://filmmusic.io/song/3408-backbay-lounge`}</a></li>
            </ul></li>
          <li parentName="ul">{`Heroic Adventure by Rafael Krux`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/5885-heroic-adventure"
                }}>{`https://filmmusic.io/song/5885-heroic-adventure`}</a></li>
            </ul></li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "https://filmmusic.io/standard-license"
            }}>{`https://filmmusic.io/standard-license`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      